import { decodeJWT, tokenFactory, injectToken } from '@wise/wise-auth';

function mapToParameter(map: Record<string, string>) {
  return Object.entries(map)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');
}

async function requestAccessToken(
  refreshToken: string
): Promise<Record<string, string> | undefined> {
  try {
    const authEndpoint = '/api/identity-server/anonymous';
    const response = await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },

      body: mapToParameter({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });

    if (response.status !== 200) {
      return undefined;
    }

    const content = await response.json();
    return content;
  } catch (error) {
    console.log('Refresh token: ', error);
    return undefined;
  }
}

export async function getAccessTokenFromRefreshToken(
  refreshOrAccessToken: string
) {
  try {
    const accessToken = decodeJWT(refreshOrAccessToken);

    // We have already an access token
    return {
      token: accessToken,
      rawToken: refreshOrAccessToken,
      refreshToken: refreshOrAccessToken,
    };
  } catch (error) {
    // noop
  }

  // Token is a refresh token or invalid
  try {
    const tokenContent = await requestAccessToken(refreshOrAccessToken);

    if (tokenContent) {
      const decodedIdToken = decodeJWT(tokenContent['id_token']);

      const token = tokenFactory({
        clientId: tokenContent['resource'],
        tenantId: decodedIdToken.tenant_id,
        scope: tokenContent['scope'],

        idToken: tokenContent['id_token'],
        idTokenExpiresIn: tokenContent['id_token_expires_in'],

        refreshToken: tokenContent['refresh_token'],
        refreshTokenExpiresIn: tokenContent['refresh_token_expires_in'],
      });
      await Promise.resolve(injectToken(token));

      return {
        token: decodedIdToken,
        rawToken: tokenContent['id_token'],
        refreshToken: refreshOrAccessToken,
      };
    }
  } catch (error) {
    // noop
  }

  return undefined;
}
