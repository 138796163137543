import { useEffect, useState } from 'react';
import { isProduction } from '@wise/wise-environments';

const config = {
  trackingUri: `//code.etracker.com/code/e.js`,
  scriptId: '_etLoader',
  // As etracker does not provide tests keys you need to create an account
  // every 30 days and use that for non-prod env to check your work....

  accountNumber: isProduction('ONB') ? '4EbHKx' : '083uhb',
  titleQuerySelector: 'title',
  // There are in between, pre-render pageids caught by etracker
  blacklistedPageIds: ['Depoteröffnung - Depoteröffnung', 'MorgenFund'],
};

const {
  trackingUri,
  scriptId,
  accountNumber,
  blacklistedPageIds,
  titleQuerySelector,
} = config;

export const ETracker = () => {
  const [title, setTitle] = useState(document.title);

  const isBlacklisted = (pageId: string) => {
    return blacklistedPageIds.includes(pageId);
  };

  const onTitleChanged = () => {
    const newTitle = document.title;
    if (!isBlacklisted(newTitle)) {
      setTitle(newTitle);
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(onTitleChanged);

    const titleElement = document.querySelector(titleQuerySelector);
    if (titleElement) {
      observer.observe(titleElement, { childList: true });
    }

    onTitleChanged();

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (title && !isBlacklisted(title)) {
      reload();
    }
  }, [title]);

  const injectScript = () => {
    const utag = window.document.createElement('script');
    utag.id = scriptId;
    utag.charset = 'UTF-8';
    utag.setAttribute('data-block-cookies', 'true');
    utag.setAttribute('data-secure-code', accountNumber);
    utag.setAttribute('data-cookieconsent', 'statistics');
    utag.src = `${trackingUri}`;
    //utag.type = 'text/javascript';
    utag.type = 'text/plain';
    utag.async = true;
    window.document.head.appendChild(utag);
  };

  const trackPageview = () => {
    if (window.et_eC_Wrapper) {
      window.et_eC_Wrapper({ et_et: accountNumber, et_pagename: title });
    }
  };

  const reload = () => {
    if (isBlacklisted(title)) return;
    const eTrackerContainer = document.getElementById(scriptId);

    window.et_pagename = title;

    if (eTrackerContainer !== null) {
      trackPageview();
      return;
    }

    injectScript();
  };

  return null;
};
