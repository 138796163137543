/* eslint-disable no-console, quote-props, complexity */
import { PureComponent } from 'react';
import { parse } from 'cookie';

import { parseUrl } from '@wise/shared/src/string-helper';
import { setup, decodeJWT } from '@wise/wise-auth';
import Switches from '@wise/wise-feature-switches';

import {
  init as sessionInit,
  setSessionItem,
  getSessionItem,
  clearSession,
} from './services/session';

import fixUrl from './services/fixUrl';
import sha1 from './services/sha1';
import {
  setConfiguration,
  setConfigurationValue,
  getConfiguration,
} from './services/configuration';

import { isStaticPage } from './services/isTenant';
import { getAccessTokenFromRefreshToken } from './services/tokenRegeneration';

import * as githashJson from './githash.json';
import * as packageJson from '../package.json';
import { ETracker } from './components/eTracker/eTracker';
import { Cookiebot } from './components/Cookiebot/Cookiebot';

const { githash } = githashJson;
const { version } = packageJson;

Switches.setSwitches(['pin', 'journeyV2']);

// This is a SHA1 map of clientid -> tenant configuration
const clientMap = {
  // LOIP testId 529e6551-4acf-46b1-97c2-e2438f2d8e95
  f3fd08bf06c2020b17c8f62258dc17e80db9e25e: () => import('./tenants/LOIP'),

  // LOIP special case 'LOIP' as sha1(), clientid is detected in tenant specific code
  '13b48f6e38b9f2bc429144a2e268dddecc9992d3': () => import('./tenants/LOIP'),

  // LOIP internal qz3hFcZns50j2t2i3xpp2hiFfHUa
  '7024772358386f6e5d48e03178a4b6820abaeed2': () => import('./tenants/LOIP'),

  // LOIP internal BKxE8fkVdhyjLSom05FJjllLlv0a
  a070e937df05c66359db8e792e0472fb621beb87: () => import('./tenants/LOIP'),

  // LOIP internal eNnbz8WEVJF8FJv3C0xZgEqotFUa
  '22e9c7d8fac8520ef274df0fbb98ca0a2b858f60': () => import('./tenants/LOIP'),

  // LOIP internal y4Hzrbxq0Dlchr7g5MlkIYM05nka
  '0cba24c396ae78a9235d5efc1562399b312f5c27': () => import('./tenants/LOIP'),

  // LOIP production ODUxxzIwNjE5NDxyOTE5xTU3xTAK
  '9312e59bb0a5a57ac2d961d6140acf3d4905aa6a': () => import('./tenants/LOIP'),

  // DWS direkt internal 8xz3ajx5NxQETSlgUx5gwzYxjUTN
  '3c996d5597c37e0b7fff845a8a0ea80b4701ec43': () =>
    import('./tenants/FamilyFriends'),

  // DWS direkt production TxjxgU5NaNlYTxjxUgEz5x3wSz8Q
  '706c799296549e33eb1099fe236bb2e1c9c22403': () =>
    import('./tenants/FamilyFriends'),

  // Godmmode internal jxQgwESTjg8xxaxYUNTzNx3U5lz5
  '6f85f68eba6e5ee2a24a48d72b90073fa01280cd': () => import('./tenants/LOIP'),

  // Godmode production xaSQxNUxUw5xj3jl8N5xTzgTYEzg
  f7b0f9fffc2017c77b3986c26766783d0cdd1783: () => import('./tenants/LOIP'),

  // Morgenfund internal BtwqV20VJJhOmw1CrBvppuk5K0gA
  '107290806a8246f434a5a74fc156c991dccb9b38': () =>
    import('./tenants/Morgenfund'),

  // Morgenfund production p2cksnDarWr4HaLWGah0bI7Gqptg
  '81c929c0fe92a87259008fd2745a90b726058b50': () =>
    import('./tenants/Morgenfund'),

  // VDW internal 9df7bxa6GcKDRFjsVz9qdaLpoJBC
  c053a36511672b937ea40dca8758e104034f7a10: () => import('./tenants/VDW'),

  // VDW production NUST5zTjENEyN8cTN1aQwNYUxzxI
  '821ecc2b035588a222c3e9dc1475ece9de2fa777': () => import('./tenants/VDW'),

  // Basler internal xjQyxjE5xTc0xzQ2NjIyODA0xjga
  '265596a4cf97c23df96af939d5c0a294a2819ee0': () => import('./tenants/Basler'),

  // Basler production NzcxOTE4Nzc4xTk4xDQ4xjY1xjUa
  bdf2abb0be206b9c36ea2b5ba380fdf15a941264: () => import('./tenants/Basler'),

  // Basler (T2) Pro internal VLanbF3QoulIaRuAhcEEawYmC05w
  a6d997c5333fd8fcc153101a456ada9050496073: () => import('./tenants/BaslerPro'),

  // Basler (T2) Pro production 2B8wn7HG83DNbFUbPXNjtL69AZJA
  a364a16ef7ff37a4fbcea427d0cdc5e2b0e9538f: () => import('./tenants/BaslerPro'),

  // Basler (T3) Premium internal 2C6mSMU6PEBamlyQllInIkkZsyoA
  c3be61324547e608461bb3aca395611b0f50e30b: () =>
    import('./tenants/BaslerPremium'),

  // Basler (T3) Premium production lVDEGTeblbXNdBBoFzU4VHShDBlA
  '5f42970777d5e9fe8442c4b2903baded92c17df8': () =>
    import('./tenants/BaslerPremium'),

  // FondsDiscount internal x3NQzxgxjl8YjUgTSTaxNU5Ewz5x
  '6d09ec11911eaa5b19f8ec41439d789206386f36': () =>
    import('./tenants/FondsDiscount'),

  // FondsDiscount production S3TNwgYxjjx5xxTxzQElgN5UaU8z
  c7e117e3eb4ade050812cdb70782c770214235d5: () =>
    import('./tenants/FondsDiscount'),

  // Edison internal NxNIUjEAB145z8xDawTgQSjjxUTN
  '650c5ae68c2a53a478993c3635f7c481d34af83d': () => import('./tenants/Edison'),

  // Edison production Uj5UTETQygajxzTESxEZNTwx8xN5
  '442a864f70f92fd953404f6f955d94d8320746ac': () => import('./tenants/Edison'),

  // BfV internal 5lTxaxj8NYz3jQ5ggxTxxUzSENwU
  '9ff4c9c474012370bbdbca07447ab3095efcebb2': () => import('./tenants/BfV'),

  // BfV production Eg85zzjxSYUTlQxU5TNx3wxNxjag
  '5113ba7f5672f070fc8a702b8b4fca1e0f80d60b': () => import('./tenants/BfV'),

  // MiniAAB dashboard internal qBAAk16xtqLqhtLAdQVfG71ws2Ya
  '6fc93dbc74829bd54ca60ca52868e042b6c1bc88': () => import('./tenants/miniaab'),

  // MiniAAB dashboard production NDx3ODI3NDY1NTUwxTIzNTA0xjAa
  '97b7cc6339343afc9b6390e3cbfa26a9713a00e9': () => import('./tenants/miniaab'),

  // FondsFinanz internal wFuMUGx20ah94GkyiUQjsTIYDdfQ
  ee93e0af6a4f9b8c8d51f4b65c1a0e3506fc9dd7: () =>
    import('./tenants/FondsFinanz'),

  // FondsFinanz production WA1DmZzXFWGBV1AHgletS4RvSQrA
  '7febebb537fdec826de0d35681beb12636c53ab9': () =>
    import('./tenants/FondsFinanz'),

  // FinanzGuru internal 9ttzmSV5IcNDlahQYZm1CeB9Cs4Q
  c58a2ce0bda174d4e9f56e9b26d44eaaefcbf948: () =>
    import('./tenants/Finanzguru'),

  // FinanzGuru production mOns8oP0yPXODKXevmGD6Nf5m4YA
  '42658c4293c1af802683093ae39d91aabfd22790': () =>
    import('./tenants/Finanzguru'),

  // Apella internal joEnWBN5RMGkxtya2ObP8Zfqa52A
  '238a5040a8561c590220301b7c8063e058ab7309': () => import('./tenants/Apella'),

  // Apella production OyGaxo6xB2MFZepgUKMGDLMZr93A
  '9c0353448a80410a477d7bb21cd1ae646e0ade2d': () => import('./tenants/Apella'),

  // NetFonds internal reWqRZ0wZQYn0GhLTpnc44yPYyUw
  af413bcb99ffc63400d8181a37f83f138dc5acfa: () => import('./tenants/NetFonds'),

  // NetFonds production 6YDC7UcLvLaP1gQ79QqOOepXv1Jw
  cf9922c66c599099721cdf450c729e6f50d2092f: () => import('./tenants/NetFonds'),

  // ETFRobo internal guJIeLtscGDNjLkO8kwWeZh9m6Xg
  e8f870c1eedbb5ed82501587fd483c531e37e079: () => import('./tenants/ETFRobo'),

  // ETFRobo production w8YLzJCaAKdO9CqUoOPVKNmslYFw
  '82a697a1c10c5f5392770e3a73ec605b8eef299e': () => import('./tenants/ETFRobo'),

  // BancoBest internal q3Py3t8eDcwLFcNiy5M2cAnBUgjQ
  cd2fc2f9e4f8dc2db1543bbdb4377cb1cfaee726: () => import('./tenants/BancoBest'),

  // BancoBest production TT49iQYgWizVMHLl1q9KBVatWJwQ
  '9dfa8e8ae2688cd49ba3f16e68c68fa6ce82ede8': () =>
    import('./tenants/BancoBest'),

  // JDC internal k1WIsEXBx8dpfJFGGeFiHIFVzSow
  '4e3fad7f5517136412fd7872114ef99ffff9e67a': () => import('./tenants/JDC'),

  // JDC production 0SAYNetfBiBQMX4F1Y1BXoajnLRw
  '0c86b9bfa0c6d204aa627305a3e10c0774aa49c7': () => import('./tenants/JDC'),

  // Buddenbrock internal mvkC3mNrsL51z22BLzG5Sn8ORWfw
  '9be30616f616c60b69a560858f71a32f0e0c1ef1': () =>
    import('./tenants/Buddenbrock'),

  // Buddenbrock production g3MRSuRKqX88O4nrMTLszAXjBarg
  c9ad96c858b3640366481213becaa5ef8467c3ed: () =>
    import('./tenants/Buddenbrock'),

  // Trigonus internal SmT935b0SUDphbGqWJqeArW9YPvw
  dfb882d0fba8d992bd36918b60320d70518b1fba: () => import('./tenants/Trigonus'),

  // Trigonus production 5WUVYU47nuszGfFyZz04UtxrA4Dg
  '6d80cb7b0b257d836001b78b4c1459154a579d1a': () =>
    import('./tenants/Trigonus'),

  // InvestFinder internal RdSr0IQqGSwt503VYzPe1j8JG2sQ
  ad3dc47b6898cd9517f88e59db48d35c28615d1e: () =>
    import('./tenants/InvestFinder'),

  // InvestFinder production CEqvRE4I6vPGzbr6JYGpfBAGVPfA
  ecc46796cd88d90c6fbbe5161ad05050ec4fd0e7: () =>
    import('./tenants/InvestFinder'),

  // IdRepair internal Kzj7S8a2S1KUQzrp56rRjR9d4NhA
  '80771b9a28543e2ebc84ad0e0aa89e6988949511': () =>
    import('./tenants/IdRepair'),

  // IdRepair production VD3WUZmRQXm4LIFcnpYiCKpsq2dg
  b09b199e8103a422cdb802215b65432751a4a522: () => import('./tenants/IdRepair'),

  // Athora internal Athora-Trustee
  '9eb0b4843f6f1a22f0d7801c89cb22e83383a371': () => import('./tenants/Athora'),

  // Norisbank / MF Family & Friends internal rOX70vD0clM2ulISukNzBRD2IjXg
  '856e3dcb06430b3b5f9c75ed119aa78c5ec2992a': () =>
    import('./tenants/Norisbank'),

  // Norisbank / MF Family & Friends production akjeH6uKijKnEnQ1QM4e1gRyNleg
  '355fe282f51adc86b65e3e607d57db1c7660eca9': () =>
    import('./tenants/Norisbank'),

  // KYC internal M4KxoqtTAlTBS9rfCzv998YY8F3Q
  '560e173bdddf765c01e458d40dbe935e1ded3ed3': () => import('./tenants/KYC'),

  // KYC production kzlJdQR3wTVmAl1s6H6bySxHXsMg
  '2b067d5c7ad8b2819b8d119aaf054b4209f264ad': () => import('./tenants/KYC'),

  // easyInvesto ESG internal mrqYbupas0gFbVZWHl19KNTFIvxQ
  d1a035dd493ba31209ee5f5b329d97eb2e92748f: () =>
    import('./tenants/FondsFinanzESG'),

  // easyInvesto ESG production 2l1XxfAFP5XcJiAPrx6RXLifsb9g
  a982c72f549a0ac46ab88c7822350d0631d86e13: () =>
    import('./tenants/FondsFinanzESG'),

  // BFV ETF ESG internal 5NDeq0UfEY2W7CL6COaYK9cC6bGw
  f22f2f1a4f5cb2637ac4f12e977625fb34aeac89: () => import('./tenants/BfVESG'),

  // BFV ETF ESG production j46rRwO3RhYKVTLJ4UOzrxon0ysg
  c3a0ef0a596f520d1682c4232dc20cb26f05ca12: () => import('./tenants/BfVESG'),
};

const { body } = window.document;
body.className += ` wise`;
body.parentElement.setAttribute('data-wise-version', version);
body.parentElement.setAttribute('data-wise-hash', githash);

function doAuthSetup(tenantId) {
  setup({
    app: 'ONB',
    tenant_id: tenantId,
  });
}

function polyfillFetch() {
  if (window.fetch) {
    return true;
  }

  console.log('Polyfill fetch()');
  return import('whatwg-fetch');
}

async function polyfillIntl() {
  if (window.Intl) {
    return true;
  }
  console.log('Polyfill intl');
  await import('intl');

  console.log('Load language definition data');
  return Promise.all([
    import('intl/locale-data/jsonp/en'),
    import('intl/locale-data/jsonp/de'),
  ]);
}

function bootLoader() {
  const bootLoaderTasks = [polyfillFetch(), polyfillIntl()];

  return Promise.all(bootLoaderTasks);
}

function shouldRestoreSession() {
  const query = parseUrl() || {};

  if (query.state) {
    try {
      const state = JSON.parse(decodeURIComponent(query.state));
      if (state.type === 'dbapi') {
        return 'dbapi';
      }
    } catch (error) {
      // noop
    }

    return false;
  }
  if (query.eid) {
    return 'tokenrestore';
  }

  return false;
}

// Clean session storage if onboarding id is != stored onboarding id
function cleanForNewSession() {
  const query = parseUrl();
  const handoverId = getSessionItem('v3/handoverId');

  if (query.tk && query.id && query.id !== handoverId) {
    clearSession();
  }
}

async function tenantLoader() {
  const query = parseUrl() || {};

  // This is used in handover case and as fallback here
  let clientId = 'LOIP';

  if (query.handover) {
    // This has to be loaded async to not start store initial values
    const { requestFirstPartyToken } = await import('./services/handover');
    const firstPartyToken = await requestFirstPartyToken(query.handover);

    if (firstPartyToken && firstPartyToken?.idToken) {
      const decodedToken = decodeJWT(firstPartyToken.idToken);
      clientId = decodedToken.tenant_id;
    } else {
      console.error('No clientid in first party token', firstPartyToken);
    }

    try {
      sessionInit(clientId);
      setSessionItem('v3/idToken', JSON.stringify(firstPartyToken.authObject));
      if (firstPartyToken.errorType) {
        setSessionItem('v3/idTokenError', firstPartyToken.errorType);
      }
    } catch (error) {
      // noop
    }
  } else if (query.tk) {
    const decodedToken = await getAccessTokenFromRefreshToken(query.tk);
    clientId = decodedToken?.token?.tenant_id ?? 'LOIP';
  } else if (query.clientId) {
    clientId = query.clientId;
  } else if (query.state) {
    const state = JSON.parse(decodeURIComponent(query.state));

    clientId = state.clientId;
  } else if (!isStaticPage()) {
    throw new Error('No clientid detected', query);
  }

  const clientHash = sha1(clientId);
  const tenantDataLoader = clientMap[clientHash];
  const loader = await tenantDataLoader();

  return loader.default(clientId);
}

function isIdentityServerCallback() {
  const { hash } = window.location;
  return hash.indexOf('code=') > 0 || hash.indexOf('id_token=') > 0;
}

function handleIdentityServerCallback() {
  console.log('Identity Server callback');

  const safeOrigin = `${window.location.protocol}//${window.location.host}`;
  let w = window;
  while (w.parent !== w) {
    // eslint-disable-line no-cond-assign
    w = w.parent;
    w.postMessage(
      {
        type: 'wise-auth',
        content: window.location.hash,
      },
      safeOrigin
    );
  }
}

class Boot extends PureComponent {
  async componentDidMount() {
    const idServerCallback = isIdentityServerCallback();
    if (idServerCallback) {
      handleIdentityServerCallback();
      return;
    }

    // Add Morgenfund body class everywhere
    document.body.classList.add('morgenfund');

    console.log(`WISE onb build ${version}-${githash}`);
    fixUrl(['/signcomplete']);

    const currentCookieState = window.document.cookie;
    const cookies = parse(currentCookieState);

    if (
      cookies.WISE_OPT_OUT === undefined ||
      cookies.WISE_TRACKING_OPT_OUT === undefined
    ) {
      window.document.cookie = 'WISE_OPT_OUT = 1';
      window.document.cookie = 'WISE_TRACKING_OPT_OUT = 1';
    }

    console.log('Load dynamic imports...');
    await bootLoader();

    console.log('Done, load tenant specific imports...');
    const tenantConfig = await tenantLoader();

    setConfiguration(tenantConfig);
    setConfigurationValue('version', `${version}-${githash}`);

    doAuthSetup(tenantConfig.clientId);
    sessionInit(tenantConfig.clientId);

    cleanForNewSession();

    console.log('Loaded configuration for', tenantConfig.tenantName);

    const idRepairClass = getConfiguration('isIdRepair')
      ? 'body-isIdRepair'
      : '';
    body.className += ` ${idRepairClass}`;

    let app;
    console.log('Also load application...');
    const restoreSession = shouldRestoreSession();
    if (restoreSession === 'dbapi') {
      app = await import('./containers/DbApiRestore');
    } else if (restoreSession) {
      app = await import('./containers/TokenRestore');
    } else {
      app = await import('./app');
    }

    console.log('Done');
    const App = await app.default();
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      App,
    });
  }

  render() {
    if (this.state && this.state.App) {
      const App = this.state.App;
      return (
        <>
          <App />
          <ETracker />
          <Cookiebot />
        </>
      );
    }

    return null;
  }
}

export default Boot;
