import { useEffect } from 'react';

const PRODcbid = '488807fb-5726-42d0-b313-76bb8aa8505f';

const config = {
  src: `https://consent.cookiebot.eu/uc.js`,
  id: 'Cookiebot',
  cbid: PRODcbid,
  blockingmode: 'auto',
};

const { src, id, cbid } = config;

export const Cookiebot = () => {
  useEffect(() => {
    injectScript();
  }, []);

  const injectScript = () => {
    const utag = window.document.createElement('script');
    utag.id = id;
    utag.setAttribute('data-cbid', cbid);
    // blocking mode is deactivated due conflict with webID library
    utag.setAttribute('data-blockingmode', 'none');

    utag.src = `${src}`;
    utag.type = 'text/javascript';
    window.document.head.appendChild(utag);
    return null;
  };

  return null;
};
